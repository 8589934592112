import { Injectable } from '@angular/core';
import { UserData } from '../../models/storage/user-data';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private userData: UserData | null = null;

  constructor() { }

  setUserData(data: UserData): void {
    this.userData = data;
    this.setWithExpiry('storedData', this.userData)
  }

  getUserData(): UserData | null {
    const itemStr = localStorage.getItem('storedData');

    if (!itemStr) {
      return null;
    }
    let item: any;  
    try {  
      item = JSON.parse(itemStr); // Attempt to parse the JSON  
    } catch (error) {  
      localStorage.clear();  
      return null; // Return null if parsing fails  
    } 
    // const item = JSON.parse(itemStr);
    
    //for session clear
    // const now = new Date().getTime();
    // if (now > item.e) {
    //   localStorage.clear();
    //   return null; // Data is no longer valid
    // }

    return item.value;
  }

  clearUserData(): void {
    localStorage.clear();
  }

  setWithExpiry(key: string, value: any) {
    // const now = new Date().getTime();
    // const expiry = now + 12 * 60 * 60 * 1000; // Convert hours to milliseconds
    const item = {
      value: value,
      // e: expiry,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }


}

import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ThemeService } from './core/theme/theme.service';
import { Platform } from '@angular/cdk/platform';
import { StorageService } from './core/store/storage/storage.service';
import { environment } from 'src/environments/environment';
import { SubscriptionFacadeService } from './facades/subscription/subscription-facade.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mvm2';
  constructor(
    private router: Router,
    private themeService: ThemeService,
    private platform: Platform,
    private storageService: StorageService,
    private subscriptionFacade: SubscriptionFacadeService
  ) {
    const cookieValue = document.cookie.split('; ').find((row) => row.startsWith('myObjectCookie='));
  if (cookieValue) {
    const myObject = JSON.parse(cookieValue.split('=')[1]);
    const retrieveData = this.storageService.getUserData();
    this.storageService.setUserData({
      ...retrieveData,
      "roleId": myObject.roleId,
      "token": myObject.token,
      "isLoggedIn": true,
      "email": myObject.email,
      "authId": myObject.authId,
      "typeId": myObject.typeId,
      "sId": myObject.stylistId
    })
    const domain= environment.domain;
    const pastDate = new Date(0).toUTCString();
    document.cookie = `myObjectCookie=; domain=${domain}; path=/; expires=${pastDate}`;
    this.getFeatureAccess(myObject.stylistId)
  } else {
  }
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.themeService.initializeTheme();
      }
      window.scrollTo(0, 0);
    })
    if (this.platform.IOS) {
      document.body.style.setProperty('-webkit-overflow-scrolling', 'none');
    }
  }
  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    // Disable momentum scrolling
    event.preventDefault();
  }

  getFeatureAccess(id) {
    try {
      let data = {
        stylistId: id,
      };
      this.subscriptionFacade.getFeatureAccess(data).subscribe({
        next: (res: any) => {
          const retrieveData = this.storageService.getUserData();
          this.storageService.setUserData({
            ...retrieveData,
            "isCalendarAccess": res.isCalendarAccess,
            "isDoubleBookingAccess": res.isDoubleBookingAccess,
            "isRecurringAccess": res.isRecurringAccess,
            "isReportsAccess": res.isReportsAccess,
            "isEmailNotification": res.isEmailNotification,
            "isSMSNotification": res.isReportsAccess,
            "locationLimitReached": res.locationLimitReached,
            "notesAccess": res.notesAccess,
            "onlineBookAccess": res.onlineBookAccess,
            "subscriptionExpired": res.subscriptionExpired
          });
          this.router.navigate(['/appointments']);
          //this.userFeedback.stopLoading();
        },
        error: (error) => {
          //this.showError(error);
          //this.userFeedback.stopLoading();
        },
      });
    } catch (error) {
      //this.showError(error);
      //this.userFeedback.stopLoading();
    }
  }
}

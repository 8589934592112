import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as auth from 'firebase/auth';
import {
  Auth,
  FacebookAuthProvider,
  OAuthProvider,
  getAuth,
  updateEmail,
} from 'firebase/auth';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { Observable, finalize } from 'rxjs';
import { StorageService } from '../../store/storage/storage.service';
import { UserData } from '../../models/storage/user-data';
import { HttpClient } from '@angular/common/http';
import { HttpHelperService } from '../../utilities/http-helper/http-helper.service';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth;
  constructor(
    private afAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private storageService: StorageService,
    private http: HttpClient,
    private httpHelper: HttpHelperService
  ) {
    const app = initializeApp(environment.firebase);
    this.auth = getAuth(app);
  }

  forgotPassword(email: string, actionCodeSettings: any) {
    return this.afAuth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  resetPassword(code: any, password: any) {
    return this.afAuth.confirmPasswordReset(code, password);
  }
  signIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signUp(email: string, password: string): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  oAuthProvider(provider: any, loginFrom: any) {
    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithPopup(provider)
        .then((res) => {
          // from login
          if (!loginFrom.isCreate) {
            this.getToken(res);
            resolve(res);
          } else {
            // from register & db email == oauth email
            if (loginFrom.email == res?.user?.providerData[0]?.email) {
              this.getToken(res);
              resolve(res);
            }
            // from fb login or email is empty
            else if (
              res?.user?.providerData[0]?.email == null ||
              res?.user?.providerData[0]?.email == ''
            ) {
              this.getToken(res);
              reject('email empty');
            } else {
              reject('email not match');
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // get token
  getToken(response: any) {
    response.user.getIdToken().then((token: string) => {
      const retrievedUserData: UserData | null =
        this.storageService.getUserData();

      if (!retrievedUserData) {
        const newUserData: UserData = { token };
        this.storageService.setUserData(newUserData);
      } else {
        retrievedUserData.token = token;
        this.storageService.setUserData(retrievedUserData);
      }
    });
  }

  googleLogin(loginFrom: any): Promise<any> {
    return this.oAuthProvider(new auth.GoogleAuthProvider(), loginFrom);
  }

  faceBookLogin(loginFrom: any): Promise<any> {
    return this.oAuthProvider(new FacebookAuthProvider(), loginFrom);
  }

  appleLogin(loginFrom: any): Promise<any> {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return this.oAuthProvider(provider, loginFrom);
  }

  updateEmail(email: string): Promise<any> {
    const user: any = getAuth().currentUser;
    return updateEmail(user, email);
  }

  deleteUser(): Promise<any> {
    const user: any = getAuth().currentUser;
    return user.delete();
  }

  signOut(): Promise<any> {
    return this.afAuth.signOut();
  }

  uploadProfileImage(filePath: string, file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              const downloadURL = fileRef.getDownloadURL();
              resolve(downloadURL);
            })
          )
          .subscribe();
      } catch (error) {
        reject(error);
      }
    });
  }

  sendOtp(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getApiUrl(
      `/api/Stylists/StylistLoginUsingOTP`
    );
    const requestOptions: Object = {
      responseType: 'text',
    };
    return this.http.post<any>(apiUrl, data, requestOptions);
  }

  verifyOtp(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getApiUrl(`/api/Stylists/CheckOTP`);
    const requestOptions: Object = {
      responseType: 'text',
    };
    return this.http.post<any>(apiUrl, data, requestOptions);
  }

  generateCustomToken(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getApiUrl(
      `/api/Clients/GenerateCustomToken`
    );
    const requestOptions: Object = {
      responseType: 'text',
    };
    return this.http.post<any>(apiUrl, data, requestOptions);
  }
  
  async loginWithCustomToken(customToken: string){
    return this.afAuth.signInWithCustomToken(customToken);
  }
}
